import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { device } from "../components/constants"

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  text-align: center;

  img {
    border-radius: 5px;
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`

const Content = ({ data }) => (
  <Layout>
    <SEO title="Our Work Folio" />

    <h1>Our Work Folio</h1>

    <Grid>
      <section>
        <Link to="/folio/show-gardens">
          <Img fluid={data.showGardensImage.childImageSharp.fluid} alt="" />
          <h2>Show Gardens</h2>
        </Link>
      </section>

      <section>
        <Link to="/folio/residential">
          <Img fluid={data.residentialImage.childImageSharp.fluid} alt="" />
          <h2>Residential</h2>
        </Link>
      </section>

      <section>
        <Link to="/folio/international">
          <Img fluid={data.internationalImage.childImageSharp.fluid} alt="" />
          <h2>International</h2>
        </Link>
      </section>

      <section>
        <Link to="/folio/schools">
          <Img fluid={data.schoolsImage.childImageSharp.fluid} alt="" />
          <h2>Schools</h2>
        </Link>
      </section>

      <section>
        <Link to="/folio/commercial">
          <Img fluid={data.commercialImage.childImageSharp.fluid} alt="" />
          <h2>Commercial</h2>
        </Link>
      </section>
    </Grid>
  </Layout>
)

export default Content

export const folioImageFormat = graphql`
  fragment folioImageFormat on File {
    childImageSharp {
      fluid(maxWidth: 375, maxHeight: 250) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    commercialImage: file(relativePath: { eq: "folio/commercial.jpg" }) {
      ...folioImageFormat
    }
    residentialImage: file(relativePath: { eq: "folio/residential.jpg" }) {
      ...folioImageFormat
    }
    schoolsImage: file(relativePath: { eq: "folio/schools.jpg" }) {
      ...folioImageFormat
    }
    showGardensImage: file(relativePath: { eq: "folio/show-gardens.jpg" }) {
      ...folioImageFormat
    }
    internationalImage: file(relativePath: { eq: "folio/international.jpg" }) {
      ...folioImageFormat
    }
  }
`
